export default {
  FirebaseConfig : {
    apiKey: "AIzaSyAEF3LZEibbtPQibiCCmjhMiNmBaATm97Q",
    authDomain: "mileout-53149.firebaseapp.com",
    databaseURL: "https://mileout-53149.firebaseio.com",
    projectId: "mileout-53149",
    storageBucket: "mileout-53149.appspot.com",
    messagingSenderId: "794048177964",
    appId: "1:794048177964:web:84d429cc9b9c5c5ee9ae89",
    measurementId: "G-TVESCHYVN5"
  }
}