import React from "react";
import { connect } from "react-redux";
import TabBar from '../tabBar/tabBar';
import HeaderBar from '../headerBar/headerBar';
import FriendsList from '../friends/friendsList';
import { Redirect, Link } from 'react-router-dom';
import { logOut, fetchUser } from '../actions';
import Loader from 'react-loader-spinner';
import './accountPageStyles.css';

class accountPage extends React.Component {

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(fetchUser(user.uid));
  }

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
  }

  render() {

    const { isAuthenticated, loggedInUser, userFetched, user } = this.props;
    const isMobile = window.innerWidth < window.innerHeight;

    if (!isAuthenticated) {
      return (
        <Redirect to='/login' />
      );
    } else if (userFetched) {

        return(
          <div>
            <HeaderBar />
            <div>
              <h1> Hey {loggedInUser.firstName} {loggedInUser.lastName} </h1>
              <FriendsList uid={user.uid} />
              <button type="button" onClick={this.handleLogout} > Logout </button>
            </div>
            <TabBar show={isMobile}/>
          </div>
        );
    } else {
      return (
        <div>
          <HeaderBar />
          <Loader color="#00e676" type="ThreeDots" className="activity-indicator"/>
          <TabBar show={isMobile} />
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    loggedInUser: state.auth.loggedInUser,
    userFetched: state.auth.userFetched,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps)(accountPage);