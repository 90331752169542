import React from 'react';
import { connect } from "react-redux";
import TabBar from './tabBar/tabBar';
import HeaderBar from './headerBar/headerBar';

class UserPage extends React.Component {
  render() {
    const isMobile = window.innerWidth < window.innerHeight;
    return (
      <div>
        <HeaderBar />
        <div>

        </div>
        <TabBar show={isMobile} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    loggedInUser: state.auth.loggedInUser,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default connect(mapStateToProps)(UserPage);