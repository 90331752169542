import { myFirebase } from "../firebase/firebase";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE
  };
};

export const logIn = (email, password) => dispatch => {
  dispatch(requestLogin());
  myFirebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(user => {
      dispatch(receiveLogin(user));
      fetchUser(user.user.uid)
    })
    .catch(error => {
      console.log('Login error', error);
      dispatch(loginError());
    })
}

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
}

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
}

const logoutError = () => {
  return {
    type: LOGIN_FAILURE
  };
}

export const logOut = () => dispatch => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch(error => {
      console.log('Logout error', error);
      dispatch(logoutError());
    })
}

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

const requestVerify = () => {
  return {
    type: VERIFY_REQUEST
  };
}

const receiveVerify = () => {
  return {
    type: VERIFY_SUCCESS
  };
}

export const verifyAuth = () => dispatch => {
  dispatch(requestVerify())
  myFirebase
    .auth()
    .onAuthStateChanged(user => {
      if (user !== null) {
        dispatch(receiveLogin(user));
      }
      dispatch(receiveVerify());
    })
}

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";


const requestUser = () => {
  return {
    type: USER_REQUEST
  };
}

const retrievedUser = loggedInUser => {
  return {
    type: USER_SUCCESS,
    loggedInUser
  };
}

const failedUser = () => {
  return {
    type: USER_FAILURE
  };
}

export const fetchUser = (uid) => dispatch => {
  if (!uid) return;
  dispatch(requestUser());
  myFirebase
    .firestore()
    .collection('users')
    .doc(uid)
    .get()
    .then(user => {
      dispatch(retrievedUser(user.data()));
    })
    .catch(error => {
      console.log(error);
      dispatch(failedUser());
    })
}
