import React from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase/firebase';
import './friendsListStyles.css';

class FriendsList extends React.Component {

  state = {
    friends: [],
  }

  componentDidMount() {
    let tempArr = [];
    db
      .collection('users')
      .doc(this.props.uid)
      .collection('friends')
      .get()
      .then(results => {
        results.forEach(result => {
          tempArr.push(result.data());
        })
      })
      .then(() => {
        this.setState({
          friends: tempArr,
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {

    const numFriends = this.state.friends.length;
    console.log(this.state.friends)

    if (numFriends === 0) {
      return (
        <div>
          <p> Add friends now to see where they're going out!</p>
        </div>
      );
    } else {
      return(
        <div className="friend-list-container">
          {this.state.friends.map((friend, index) => {
            return (<Link 
                      className="friend-name-text"
                      key={index}
                      to={`{/user/${index + 1}}`}
                    > 
                      {friend.firstName} {friend.lastName} <br/>
                    </Link>);
          })}
        </div>
      );
    }
  }
}

export default (FriendsList);